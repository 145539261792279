<template>
    <div>
        <div>
            <div>
                <div>
                    <label for="writer-name">작성자 이름</label>
                    <input type="text" id="writer-name" v-model="board.writer.name" @input="$emit('input', board)" />
                </div>
                <div>
                    <label for="subject">제목</label>
                    <input type="text" id="subject" v-model="board.subject" @input="$emit('input', board)" />
                </div>
                <div>
                    <naver-smarteditor v-model="board.content" @input="$emit('input', board)"></naver-smarteditor>
                </div>
                <div>
                    <label for="password">비밀번호</label>
                    <input type="password" id="password" v-model="board.password" maxlength="6" @input="$emit('input', board)" />
                </div>

                <!-- <div>
                    <div>썸네일 이미지</div>
                    <div>
                        <div>
                            <label class="file" for="thumb">
                                <input
                                    id="thumb"
                                    class="input"
                                    type="file"
                                    @change="
                                        ($event) => {
                                            board.thumb = $event.target.files[0];
                                            $emit('input', board);
                                        }
                                    "
                                />
                                <span class="text"
                                    ><span v-if="board.thumb">{{ board.thumb.name }}</span></span
                                >
                                <span class="button button--primary">파일선택</span>
                            </label>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <!-- 버튼 모바일에서 가운데 정렬 -->
        <div>
            <!-- <div class="left">
                <a class="button button--border" href=""><span>수정</span></a>
                <a class="button button--border" href=""><span>삭제</span></a>
                <a class="button button--border" href=""><span>답글</span></a>
            </div> -->
            <div>
                <a @click="$router.go(-1)" class="button button--border"><span>취소</span></a>
                <a @click="$emit('save')" class="button button--primary"><span>확인</span></a>
            </div>
        </div>
    </div>
</template>

<script>
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
export default {
    components: {
        NaverSmarteditor,
    },
    props: {
        value: { type: Object, default: null },
    },
    data() {
        return {
            board: this.$props.value,
        };
    },
    watch: {
        value() {
            this.board = this.value;
        },
    },
};
</script>
